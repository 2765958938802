import React from 'react';
import { Pane, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import {
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
    setSearchAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import { assetModel } from '../../../models';
import { assetService } from '../../../services';
import { getAsset } from '../../../reducers/asset-reducer/asset.actions';
import { errorToast } from '../../toasts';

const SearchResultModal = ({
    searchResults,
    searchInput,
    setSearchOverlayCheck,
    loading,
    setLoading,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentPath = useLocation().pathname.split('/');

    const selectAsset = async (asset) => {
        dispatch(setSearchAction(''));
        setLoading(true);
        try {
            if (asset.type === 'park') {
                dispatch(setCurrentAssetAction(asset));
                dispatch(setCurrentBuildingAction(null));
                dispatch(setCurrentTenantAction(null));
                if (
                    currentPath.includes('info') ||
                    currentPath.includes('compliance')
                ) {
                    navigate(
                        `/portfolio/properties/parks/${asset.id}/${
                            currentPath[currentPath.length - 1]
                        }`
                    );
                } else {
                    navigate(`/${currentPath[1]}?currentAssetId=${asset.id}`);
                }
            }
            if (asset.type === 'building') {
                dispatch(setCurrentTenantAction(null));
                dispatch(setCurrentAssetAction(null));
                if (asset.parentId) {
                    dispatch(getAsset(asset.parentId, false, true));
                    dispatch(setCurrentBuildingAction(asset));

                    if (
                        currentPath.includes('info') ||
                        currentPath.includes('compliance')
                    ) {
                        navigate(
                            `/portfolio/properties/parks/${
                                asset.parentId
                            }buildings/${asset.id}/${
                                currentPath[currentPath.length - 1]
                            }`
                        );
                    } else {
                        navigate(
                            `${currentPath[0]}?currentAssetId=${asset.parentId}&currentBuildingId=${asset.id}`
                        );
                    }
                } else {
                    dispatch(setCurrentBuildingAction(asset));
                    if (
                        currentPath.includes('info') ||
                        currentPath.includes('compliance')
                    ) {
                        navigate(
                            `/portfolio/properties/buildings/${asset.id}/${
                                currentPath[currentPath.length - 1]
                            }`
                        );
                    } else {
                        navigate(
                            `/${currentPath[1]}?currentBuildingId=${asset.id}`
                        );
                    }
                }
            }
            if (asset.type === 'tenant') {
                dispatch(setCurrentAssetAction(null));
                dispatch(setCurrentBuildingAction(null));
                if (asset.parentId) {
                    const building = await assetService.getAssetById(
                        asset.parentId
                    );
                    if (building) {
                        if (building?.parentId) {
                            const park = await assetService.getAssetById(
                                building.parentId
                            );
                            dispatch(setCurrentAssetAction(assetModel(park)));
                            dispatch(
                                setCurrentBuildingAction(assetModel(building))
                            );
                            dispatch(setCurrentTenantAction(asset));
                            if (
                                currentPath.includes('info') ||
                                currentPath.includes('compliance')
                            ) {
                                navigate(
                                    `/portfolio/properties/parks/${
                                        building.parentId
                                    }/buildings/${asset.parentId}/tenants/${
                                        asset.id
                                    }/${currentPath[currentPath.length - 1]}`
                                );
                            } else {
                                navigate(
                                    `${currentPath[0]}?currentAssetId=${building.parentId}&currentBuildingId=${building.id}&currentTenantId=${asset.id}`
                                );
                            }
                        } else {
                            dispatch(
                                setCurrentBuildingAction(assetModel(building))
                            );
                            dispatch(setCurrentTenantAction(asset));
                            if (
                                currentPath.includes('info') ||
                                currentPath.includes('compliance')
                            ) {
                                navigate(
                                    `/portfolio/properties/buildings/${
                                        asset.parentId
                                    }/tenants/${asset.id}/${
                                        currentPath[currentPath.length - 1]
                                    }`
                                );
                            } else {
                                navigate(
                                    `${currentPath[0]}?currentBuildingId=${building.id}&currentTenantId=${asset.id}`
                                );
                            }
                        }
                        return;
                    }
                    if (
                        currentPath.includes('info') ||
                        currentPath.includes('compliance')
                    ) {
                        navigate(
                            `/portfolio/properties/tenants/${asset.id}/${
                                currentPath[currentPath.length - 1]
                            }`
                        );
                    } else {
                        navigate(
                            `/${currentPath[1]}?currentTenantId=${asset.id}`
                        );
                    }
                }
            }
        } catch (error) {
            errorToast(
                `An Error Occurred: ${get(
                    error,
                    'response.data.message',
                    `An unexpected error occurred.`
                )}`
            );
        } finally {
            setLoading(false);
            setSearchOverlayCheck(false);
        }
    };

    if (loading) {
        return null;
    }

    return (
        <Pane
            width="70%"
            height="auto"
            maxHeight={500}
            backgroundColor="white"
            position="fixed"
            zIndex={5}
            top={144}
            border="none"
            borderBottomLeftRadius="4px"
            borderBottomRightRadius="4px"
            borderTop=" solid 2px #DFDFDF"
            overflowY="auto"
        >
            {!searchResults.length && searchInput ? (
                <Pane
                    width="100%"
                    height={60}
                    borderBottom="solid 2px #DFDFDF"
                    display="flex"
                    alignItems="center"
                    paddingLeft={20}
                >
                    <Text color="black" fontSize={16}>
                        No Result Found!
                    </Text>
                </Pane>
            ) : (
                searchResults.map((asset) => (
                    <Pane
                        width="100%"
                        color="black"
                        fontSize={16}
                        height={60}
                        borderBottom="solid 2px #DFDFDF"
                        display="flex"
                        alignItems="center"
                        paddingLeft={20}
                        cursor="pointer"
                        onClick={() => selectAsset(asset)}
                    >
                        <Text color="black" fontSize={16}>
                            {asset.assetName}
                        </Text>
                    </Pane>
                ))
            )}
        </Pane>
    );
};

SearchResultModal.propTypes = {
    searchResults: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchInput: PropTypes.string.isRequired,
    setSearchOverlayCheck: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
};

export default SearchResultModal;
