import React, { useEffect, useCallback, useMemo } from 'react';
import { SideSheet, Pane, Heading } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { taskSelector } from '../../../reducers/task-reducer/task.reducer';
import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { portfolioSelector } from '../../../reducers/portfolio-reducer/portfolio.reducer';
import {
    createPortfolio,
    getAllPortfoliosForBusiness,
} from '../../../reducers/portfolio-reducer/portfolio.actions';
import { colors } from '../../../theme/theme';
import {
    getTenant,
    getBuilding,
    getAsset,
} from '../../../reducers/asset-reducer/asset.actions';

import { AutoFocusConsumer } from '../../atoms';

import { getAvailableAssignees } from '../../../reducers/task-reducer/task.actions';
import { getAllContactsForBusiness } from '../../../reducers/contacts-reducer/contacts.actions';
import { getNotifications } from '../../../services/sub-services/notifications-service/notification.service';
import AssetMigrationDetails from './migration-forms/asset-migration-form';

const ASSET_TYPES = {
    park: 'Park',
    building: 'Building',
    tenant: 'Tenant',
};

const MIGRATION_TO = {
    tenant: ['tenant', 'building'],
    building: ['building', 'park'],
};

function AssetMigration({
    showMigrationSideBar,
    close,
    submitForm,
    onSuccess,
    onFailure,
}) {
    const { assetId, buildingId, tenantId } = useParams();

    const dispatch = useDispatch();

    const { assignees } = useSelector(taskSelector);
    const { contacts } = useSelector(contactsSelector);
    const { portfolios } = useSelector(portfolioSelector);

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const assetDetails = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    const getData = useCallback(async () => {
        if (tenantId && tenantId !== 'undefined' && !currentTenant) {
            await dispatch(getTenant(tenantId));
        }
        if (buildingId && buildingId !== 'undefined' && !currentBuilding) {
            await dispatch(getBuilding(buildingId));
        }
        if (assetId && assetId !== 'undefined' && !currentAsset) {
            await dispatch(getAsset(assetId, true, true, true));
        }
        await Promise.all([
            dispatch(getAllContactsForBusiness()),
            dispatch(getNotifications()),
        ]);
    }, [
        tenantId,
        currentTenant,
        buildingId,
        currentBuilding,
        assetId,
        currentAsset,
        dispatch,
    ]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        dispatch(getAvailableAssignees());
        dispatch(getAllContactsForBusiness());
    }, [dispatch]);

    const handleMigrationFormSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
            let portfolio = _.find(portfolios, {
                name: values.portfolioType,
            });
            if (!portfolio) {
                portfolio = await dispatch(
                    createPortfolio({
                        portfolioType: values.portfolioType,
                    })
                );
                await dispatch(getAllPortfoliosForBusiness());
            }
            await submitForm({
                ...values,
                portfolioId: portfolio.id,
            });
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            onFailure(errorMessage);
            setSubmitting(false);
        } finally {
            const updatedAsset = await dispatch(
                getAsset(values.id, false, true, false)
            );
            onSuccess(updatedAsset);
            setSubmitting(false);
        }
    };

    return (
        <SideSheet
            isShown={showMigrationSideBar}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
        >
            <AutoFocusConsumer />
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>
                        Migrating {ASSET_TYPES[assetDetails?.type]}
                    </Heading>
                </Pane>
            </Pane>
            <Pane
                flex="1"
                overflowY="scroll"
                background={colors.tint1}
                padding={16}
            >
                <Pane maxWidth={400} marginX="auto">
                    <AssetMigrationDetails
                        assignees={assignees}
                        asset={assetDetails}
                        contacts={contacts}
                        migrationTo={MIGRATION_TO[assetDetails.type]}
                        handleMigrationFormSubmit={handleMigrationFormSubmit}
                    />
                </Pane>
            </Pane>
        </SideSheet>
    );
}

AssetMigration.propTypes = {
    showMigrationSideBar: PropTypes.bool.isRequired,
    close: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default AssetMigration;
