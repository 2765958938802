import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pane, Heading } from 'evergreen-ui';
import { colors } from '../../theme/theme';

import Breadcrumbs from '../../components/molecules/bread-crumbs';

function AssetsHeader({ asset }) {
    return (
        <Pane
            display="flex"
            backgroundColor={colors.white}
            paddingLeft={30}
            justifyContent="space-between"
            width="100%"
            zIndex={1}
            borderBottom="0.5px solid #C1C4D6"
            overflowY="auto"
        >
            <Pane
                display="flex"
                flexDirection="column"
                gap={10}
                paddingY={24}
                width="100%"
            >
                <Breadcrumbs />
                <Pane
                    display="flex"
                    alignItems="center"
                    width="100%"
                    paddingRight={25}
                    justifyContent="space-between"
                >
                    <LinkHeading size={900} fontSize={28} display="flex">
                        {asset?.assetName}
                    </LinkHeading>
                </Pane>
            </Pane>
        </Pane>
    );
}

AssetsHeader.propTypes = {
    asset: PropTypes.string.isRequired,
};

const LinkHeading = styled(Heading)`
    display: flex;
    cursor: default;
`;

export default AssetsHeader;
