import React, { useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ComplianceDocumentUploadHistory from './compliance-document-upload-history';
import {
    assetSelector,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import AssetSidebarUploadRequest from './asset-sidebar-upload-requests';
import { colors } from '../../../theme/theme';
import AssetSidebarAssetDetails from './asset-sidebar-asset-details';
import AssetMigration from '../asset-migration/AssetMigration';
import { assetService } from '../../../services';
import { assetModel } from '../../../models';
import {
    getAsset,
    getBuilding,
} from '../../../reducers/asset-reducer/asset.actions';
import { successToast, errorToast } from '../../toasts';

const ComplianceDetailsSidebar = ({
    showMigrationSideBar,
    handleCloseSideBar,
    showEditAssetSidebar,
    create,
    update,
}) => {
    const { pathname } = useLocation();
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const submitMigrateAssetForm = assetService.migrateAssets;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const info = pathname.split('/').find((path) => path === 'info');
    const onMigrateAssetSuccess = async (updatedAsset) => {
        if (updatedAsset.type === 'park') {
            dispatch(setCurrentAssetAction(assetModel(updatedAsset)));
        }
        if (updatedAsset.type === 'building') {
            dispatch(setCurrentBuildingAction(assetModel(updatedAsset)));
            if (updatedAsset.parentId) {
                dispatch(getAsset(updatedAsset.parentId, false, true));
            }
        }
        if (updatedAsset.type === 'tenant') {
            dispatch(setCurrentTenantAction(assetModel(updatedAsset)));
            const building = await dispatch(getBuilding(updatedAsset.parentId));
            if (building.parentId) {
                dispatch(getAsset(building.parentId));
            }
        }
        handleCloseSideBar();
        navigate(
            `/portfolio/properties/${updatedAsset.type}s/${updatedAsset.id}/info`
        );
        successToast('Asset successfully migrated');
    };

    const onMigrateAssetFailure = (errorMessage) => {
        handleCloseSideBar();
        errorToast(errorMessage);
    };

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset || null,
        [currentTenant, currentBuilding, currentAsset]
    );

    const isAssetInfoVisible = pathname.includes('info');

    if (!currentAsset && !currentBuilding && !currentTenant) {
        return null;
    }

    return (
        <div>
            {info ? (
                <PaneWithScrollbar
                    width="100%"
                    height="100%"
                    backgroundColor="#FAFBFF"
                    border="0.5px solid #C1C4D6"
                    position="fixed"
                    overflowY="auto"
                >
                    <Pane
                        padding={28}
                        paddingRight={46}
                        height="100%"
                        width="25%"
                    >
                        <AssetSidebarAssetDetails
                            key={`${currentTenant?.id}-${currentBuilding?.id}-${currentAsset?.id}`}
                            isVisible={isAssetInfoVisible && !!asset}
                            asset={asset}
                            showEditAssetSidebar={showEditAssetSidebar}
                            create={create}
                            update={update}
                        />
                        <AssetSidebarUploadRequest
                            isVisible={!isAssetInfoVisible && !!asset}
                        />
                        <ComplianceDocumentUploadHistory />
                        {showMigrationSideBar && (
                            <AssetMigration
                                showMigrationSideBar={showMigrationSideBar}
                                close={handleCloseSideBar}
                                submitForm={submitMigrateAssetForm}
                                onSuccess={onMigrateAssetSuccess}
                                onFailure={onMigrateAssetFailure}
                            />
                        )}
                    </Pane>
                </PaneWithScrollbar>
            ) : (
                <PaneWithScrollbar
                    width={313}
                    right={20}
                    top={276}
                    bottom={20}
                    backgroundColor="#FAFBFF"
                    border="0.5px solid #C1C4D6"
                    borderRadius={4}
                    position="fixed"
                    overflowY="auto"
                >
                    <Pane padding={28} paddingRight={46} height="100%">
                        <AssetSidebarAssetDetails
                            key={`${currentTenant?.id}-${currentBuilding?.id}-${currentAsset?.id}`}
                            isVisible={isAssetInfoVisible && !!asset}
                            asset={asset}
                            showEditAssetSidebar={showEditAssetSidebar}
                            create={create}
                            update={update}
                        />
                        <AssetSidebarUploadRequest
                            isVisible={!isAssetInfoVisible && !!asset}
                        />
                        <ComplianceDocumentUploadHistory />
                    </Pane>
                </PaneWithScrollbar>
            )}
        </div>
    );
};

ComplianceDetailsSidebar.propTypes = {
    showMigrationSideBar: PropTypes.bool.isRequired,
    handleCloseSideBar: PropTypes.func.isRequired,
    showEditAssetSidebar: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
};

export default ComplianceDetailsSidebar;

const PaneWithScrollbar = styled(Pane)`
    border: 0.5px solid #c1c4d6;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${colors.transparent};
    }
    &::-webkit-scrollbar-thumb {
        background-color: #8f95b2;
        border-radius: 5px;
    }
`;
