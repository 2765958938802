const assetBaseUrl = '/asset';
const buildingsBaseUrl = '/buildings';

export default {
    createAssetUrl: () => `${buildingsBaseUrl}/new`,
    updateAssetUrl: () => `${assetBaseUrl}/edit`,
    getAssetByIdUrl: () => `${buildingsBaseUrl}/data`,
    getAssetsUrl: () => buildingsBaseUrl,
    migrateAssetUrl: (id) => `${buildingsBaseUrl}/${id}`,
};
